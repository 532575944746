html {
  font-family: "Lato", "museo-sans-rounded", "Helvetica Neue", Arial, sans-serif;
  /* -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: #EAB098;
  background: #4c1d20;
  
}

html,
body,
#root {
  height: 100%;
}

.base-view {
}

.nazer-img {
  /* background-image: linear-gradient(to bottom left, rgba(212, 17, 17, 0.3), rgba(6, 124, 25, 0.6)), url(../img/nazer.jpg); */
  background-image: url(../img/nazer.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.animate-hover-bounce-4 {
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
  /* background: #FFF; */
}

.animate-hover-bounce-4:hover {
  box-shadow: 0 0px 4px 0 rgba(0, 0, 0, 0.2);
  text-decoration: none;
  -webkit-transform: translateY(-1px);
  -moz-transform: translateY(-1px);
  -ms-transform: translateY(-1px);
  -o-transform: translateY(-1px);
  transform: translateY(-1px);
}

.animate-hover-bounce-4:active {
  box-shadow: 0 0px 4px 0 rgba(0, 0, 0, 0.2);
  text-decoration: none;
  -webkit-transform: translateY(-4px);
  -moz-transform: translateY(-4px);
  -ms-transform: translateY(-4px);
  -o-transform: translateY(-4px);
  transform: translateY(-4px);
}

.shadow-box {
  box-shadow: 0 10px 25px 0 rgba(49, 49, 93, 0.1);
  border-radius: 5px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.cursor-nodrag {
  user-drag: none;
  -webkit-user-drag: none;
}

.cursor-noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
.profile-center-block {
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
